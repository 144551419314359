<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            preview: false,
            trash: false,
            copy: false,
            stats: false,
            leads: false,
          }"
          :search="search"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neue Seite anlegen</span>
    </v-tooltip>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  created: function () {
    this.getData(); // call it immediatly
  },

  name: "overview",

  components: {
    PageHeader,
    DataTable,
  },

  data() {
    return {
      pageTitle: "Landingpage-Templates",
      pageDescription:
        "Hier werden die Templates der Landingpages verwaltet. Landingpage-Templates können einer Kampagne zugewiesen werden. Das System erstellt daraufhin eine Kopie des Templates und weist diese Kopie einer Kampagne zu. Die Kopie kann dann bearbeitet werden.",
      edit: true,
      trash: true,
      copy: false,
      search: "",
      headers: [],
      items: [],
      preloader: false,
    };
  },

  methods: {
    async createNew() {
      /* create new entry */
      let response = await this.postRequest("pages"); // await data from mixin

      if (response.status === 200) {
        this.items.push(response.data.data);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("pages"); // await data from mixin
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id", width: "80px" },
          { text: "Titel", value: "title" },
          { text: "", value: "actions", sortable: false, width: "80px" },
        ];

        this.items = response.data.data;
      }
    },

    /* Emitted Actions:
    Hier werden die aus der <DataTable></DataTable> Komponente 'emittete' Werte verarbeitet. 
    Die Komponente übermittelt die Button-Klicks ... editItem etc.*/

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "tech.pages.:id",
          params: { id: value.itemId },
        });
      }
    },
  },

  mixins: [apiRequest],
};
</script>
