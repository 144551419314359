var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"page"}},[_c('PageHeader',{attrs:{"pageTitle":_vm.pageTitle,"pageDescription":_vm.pageDescription}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"items":_vm.items,"headers":_vm.headers,"itemActions":{
          edit: true,
          preview: false,
          trash: false,
          copy: false,
          stats: false,
          leads: false,
        },"search":_vm.search,"preloader":_vm.preloader},on:{"tableAction":_vm.emittedAction}})],1)],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fixed":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.createNew}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Neue Seite anlegen")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }